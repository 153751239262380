import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { definitions } from '@/apitypes';
import * as actions from '@/store/actions';
import { GetRebateCodeBySignQuery, Quantity, TotalPriceInformationResponse } from '@/types';
import { AxiosResponse } from 'axios';
import { apiClient, throwSubmissionError } from '@/utils';
import { formValueSelector } from 'redux-form';
import { ApplicationState } from '@/store';
import Notifications from 'react-notification-system-redux';

interface State {
    entity: TotalPriceInformationResponse | null;
    loading: boolean;
    error: any;
}
const initialState: State = {
    entity: null,
    loading: false,
    error: null,
};


// Thunks

export const applyRebateCode = createAsyncThunk(
    '/services/calculatePrice/APPLY_REBATE_CODE',
    async (rebateCodeSign: number, thunkApi) => {
        try {
            const state = thunkApi.getState() as ApplicationState;
            const companyId = state.company.data?.Id;
            const serviceId = formValueSelector('booking')(state, 'ServiceId');
            const customerEmail = formValueSelector('booking')(state, 'Customer.Email');
            const from: string = formValueSelector('booking')(thunkApi.getState() as any, 'From');
            const to: string = formValueSelector('booking')(thunkApi.getState() as any, 'To');
            const quantities: Quantity[] = state.booking?.quantities || [];

            const getbysignResponse: AxiosResponse = await apiClient.get('/rebatecodes/getbysign', {
                params: {
                    RebateCodeSign: rebateCodeSign,
                    Date: from,
                    CustomerEmail: customerEmail,
                    ServiceId: serviceId,
                    CompanyId: companyId,
                },
            });

            const calculatePriceResponse: AxiosResponse<
                definitions['TotalPriceInformationResponse']
            > = await apiClient.put(`/services/${serviceId}/calculateprice`, {
                Id: serviceId,
                Interval: {
                    From: from,
                    To: to,
                },
                Quantities: quantities || [],
                RebateCodeIds: [getbysignResponse.data.Id],
                CustomerEmail: customerEmail,
            });

            

            return thunkApi.fulfillWithValue(calculatePriceResponse.data);
        } catch (error: any) {
            thunkApi.dispatch(
                Notifications.show(
                    { title: error.response?.data?.ResponseStatus?.Message as string },
                    'error'
                )
            );
            return thunkApi.rejectWithValue(throwSubmissionError(error));
        }
    }
);

export const removeRebateCode = createAsyncThunk(
    '/services/calculatePrice/REMOVE_REBATE_CODE',
    async (rebateCodeId: number, thunkApi) => {
        try {
            const state = thunkApi.getState() as ApplicationState;
            const serviceId = formValueSelector('booking')(state, 'ServiceId');
            const customerEmail = formValueSelector('booking')(state, 'Customer.Email');
            const from: string = formValueSelector('booking')(thunkApi.getState() as any, 'From');
            const to: string = formValueSelector('booking')(thunkApi.getState() as any, 'To');
            const quantities: Quantity[] = state.booking?.quantities || [];
            const rebateCodeIds = state.calculatePrice.entity?.AppliedCodes || []

            const calculatePriceResponse: AxiosResponse<
                definitions['TotalPriceInformationResponse']
            > = await apiClient.put(`/services/${serviceId}/calculateprice`, {
                Id: serviceId,
                Interval: {
                    From: from,
                    To: to,
                },
                Quantities: quantities || [],
                RebateCodeIds: rebateCodeIds.filter(code => code.RebateCodeId === rebateCodeId),
                CustomerEmail: customerEmail,
            });

            return thunkApi.fulfillWithValue(calculatePriceResponse.data);
        } catch (error: any) {
            thunkApi.dispatch(
                Notifications.show(
                    { title: error.response?.data?.ResponseStatus?.Message as string },
                    'error'
                )
            );
            return thunkApi.rejectWithValue(throwSubmissionError(error));
        }
    }
);

// Slice

const calcualtePriceSlice = createSlice({
    name: 'calculatePrice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(actions.calculatePriceRequest, (state, action) => {
            state.loading = true;
        });

        builder.addCase(actions.calculatePriceSuccess, (state, action) => {
            state.entity = action.payload;
            state.loading = false;
        });

        builder.addCase(actions.calculatePriceFail, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        });

        builder.addCase(applyRebateCode.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(applyRebateCode.fulfilled, (state, action) => {
            state.loading = false;
            // @ts-ignore
            state.entity = action.payload;
        });

        builder.addCase(applyRebateCode.rejected, (state, action) => {
            state.loading = false;
        });

        // 

        builder.addCase(removeRebateCode.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(removeRebateCode.fulfilled, (state, action) => {
            state.loading = false;
            // @ts-ignore
            state.entity = action.payload;
        });

        builder.addCase(removeRebateCode.rejected, (state, action) => {
            state.loading = false;
        });

    },
});

export default calcualtePriceSlice;
