import { all, put, select } from 'redux-saga/effects';
import { getFormValues, arrayPush, registerField, change, stopSubmit, formValues, formValueSelector } from 'redux-form';
import { differenceInMinutes } from 'date-fns'

import { format, addYears, toDate, isBefore } from '@/utils/datetime';
import { apiClient } from '@/utils';
import * as actions from '@/store/actions';
import { throwSubmissionError } from '@/utils';
import { stringifyNestedParams } from '@/utils/common';
import { AnyAction } from 'redux';
import { CustomField, Quantity, Time } from '@/types';
import { AxiosResponse } from 'axios';
import { RootState } from 'MyTypes';
import { definitions } from '@/apitypes';
import { BookingUserQueueItemResponse } from '@/types';
import { ApplicationState } from '@/store';
import { flatten } from 'lodash';
import { setSubmitFailed } from 'redux-form'
import Notifications from 'react-notification-system-redux';

const DATE_FORMAT = 'yyyy-MM-dd';

export function* fetchServiceWithPrice(action: AnyAction) {
    const bookingForm: {[key in string]: any} = yield select(getFormValues('booking'));

    try {        
        const response: AxiosResponse = yield apiClient.get('/services', {
            params: {
                Id: bookingForm.ServiceId,
                IncludeBookingCustomFields: true,
                IncludeCustomFieldValues: true,
                IncludeCustomFields: true,
                IncludePrices: true,
                ...({
                    ...(bookingForm?.From ? { PriceDate: format(bookingForm.From, DATE_FORMAT) } : {}),
                    ...(bookingForm?.From ? { PriceTime: format(bookingForm.From, 'HH:mm') } : {})
                })
            },
        });

        const service = response.data.Results[0];

        yield put({
            type: actions.FETCH_SERVICE_WITH_PRICE.SUCCESS,
            payload: service,
        });

        yield put(change('booking', 'CustomFields', []));
        yield put(registerField('booking', 'CustomFields', 'FieldArray'));
        yield all(
            service.BookingCustomFields.map((field: CustomField) =>
                put(
                    arrayPush('booking', 'CustomFields', {
                        Id: field.Id,
                        Value: service.DefaultValue,
                        meta: field,
                    })
                )
            )
        );

        const quantities: any[] | undefined = yield select((s: ApplicationState) => s.booking.quantities)
        yield put(
            actions.calculatePriceRequest({
                Id: bookingForm.ServiceId,
                Interval: {
                    From: bookingForm.From,
                    To: bookingForm.To,
                },
                Quantities: quantities || [],
            })
        );
    } catch (error) {
        yield put({
            type: actions.FETCH_SERVICE_WITH_PRICE.FAILURE,
            payload: error,
        });
    }
}

export function* bookingFetchAvailableTimes({ payload }: AnyAction) {
    const { From, To, Resources, ServiceId, Duration } = payload;

    const params: any = {
        From: format(From, DATE_FORMAT),
        To: format(To, DATE_FORMAT),
        ...( Duration ? { Duration } : {})
    };

    // When allResources is selected we want to show all available times,
    // but keep other resources that have been selected
    if(Resources && Array.isArray(Resources)) {
        const filteredResources = Resources
            .filter((resource: any) => resource.ResourceId !== 'allResources');

        params.Resources = stringifyNestedParams(filteredResources);
    }

    try {
        const response: AxiosResponse = yield apiClient.get(`/services/${ServiceId}/availabletimes`, {
            params,
        });

        yield put(actions.FETCH_AVAILABLE_TIMES.success({ Results: response.data.Times }));

        if (response.data.Times.length === 0) {
            yield put(
                actions.FETCH_NEXT_FREE_TIME.request({
                    ServiceId,
                    ...params,
                    To: format(addYears(From, 1), DATE_FORMAT),
                })
            );
        }
    } catch (error) {
        yield put(actions.FETCH_AVAILABLE_TIMES.failure(error));
    }
}

export function* fetchNextFreeTime({ payload: { ServiceId, Resources, ...params } }: AnyAction) {
    try {
        const response: AxiosResponse = yield apiClient.get(`/services/${ServiceId}/nextfreetime`, {
            params: {
                ...params,
                Resources: stringifyNestedParams(Resources),
            },
        });

        yield put(actions.FETCH_NEXT_FREE_TIME.success(response.data));
    } catch (error) {
        yield put(actions.FETCH_NEXT_FREE_TIME.failure(error));
    }
}

export function* createBooking({ payload }: AnyAction) {
    const action = actions.CREATE_BOOKING;

    const bookingForm: {[key in string]: any} = yield select(getFormValues('booking'));
    const quantities: {[key in string]: any} = yield select((state) => state.booking.quantities);
    const finalService: {[key in string]: any} = yield select((state) => state.booking.finalService.data);
    const location: RootState['router']['location'] = yield select((state) => state.router.location);
    const time: Time = yield select((state: ApplicationState) => state.booking.time);

    const {
        customerInputMode,
        ServiceId,
        PaymentOption,
        BookedComments,
        CommentsToCustomer,
        CustomFields,
        SendSmsConfirmation,
        SendEmailReminder,
        SendEmailConfirmation,
        SendSmsReminder,
        Resources,
        Customer,
        DatesToRepeat,
        From,
        To,
        repeat,
        Id
    } = bookingForm;

    const from = toDate(payload?.From);
    const to = toDate(payload?.To);
    const serviceDuration = finalService?.Duration;
    const currentDuration = Math.abs(differenceInMinutes(from, to));

    let AllowBookingOutsideSchedules;
    if(location.pathname === '/scheduler') {
        AllowBookingOutsideSchedules = true
    } else if (serviceDuration === currentDuration) {
        AllowBookingOutsideSchedules = false;
    } else if (serviceDuration !== currentDuration) {
        AllowBookingOutsideSchedules = true;
    }
    
    try {
        if(finalService?.EnableBookingQueue && time && time.Free === 0) {
            const payload: definitions['CreateBookingUserQueue'] = {
                Customer,
                CustomerId: Customer.Id,
                ServiceId: finalService.Id,
                From: from.toISOString(),
                To: to.toISOString(),
                // @ts-ignore
                Quantities: quantities
            };

            const response: AxiosResponse<BookingUserQueueItemResponse> = yield apiClient.post(`/bookinguserqueue`, payload);
           
            const successPayload: definitions['CreateBookingsResponse'] & { addedToQueue: BookingUserQueueItemResponse } = {
                Created: [response.data.BookingUserQueue],
                Failed: [],
                addedToQueue: response.data,
                ...response.data
            }
            
            yield put(action.success(successPayload));
        } else {
            const rebateCodeIds: number[] = yield select((s: ApplicationState) => s.calculatePrice.entity?.AppliedCodes?.map(a => a.RebateCodeId) || []);
            const response: AxiosResponse = yield apiClient.post(`/bookings/repeat`, {
                DatesToRepeat: repeat
                    ? (flatten(DatesToRepeat) as { From: Date; To: Date; selected: boolean }[])
                          .filter((date) => date.selected)
                          .map((date) => ({
                              From: date.From,
                              To: date.To,
                              Quantities: quantities,
                          }))
                    : [{ From, To, Quantities: quantities }],
                ServiceId,
                PaymentOption,
                BookedComments,
                CommentsToCustomer,
                SendSmsConfirmation,
                SendEmailReminder,
                SendEmailConfirmation,
                SendSmsReminder,
                Resources: Resources
                    ? Object.keys(Resources)
                          .reduce(
                              (acc: any[], key: string) => [
                                  ...acc,
                                  ...Resources[key].map((ResourceId: number) => ({
                                      // _1234 => 1234
                                      ResourceTypeId: key.slice(1),
                                      ResourceId,
                                  })),
                              ],
                              []
                          )
                          .filter((obj) => !!obj.ResourceId)
                    : undefined,
                Quantities: quantities,
                CustomFields,
                CustomerId: customerInputMode === 'search' ? Customer.Id : undefined,
                Customer,
                CustomerCustomFields:
                Customer.CustomFields,
                AllowBookingOutsideSchedules,
                RebateCodeIds: rebateCodeIds
            });
    
            if (response?.data?.Created?.length > 0) {
                yield put(action.success(response.data));
            } else if (response?.data?.Failed[0]?.Reason) {
                const errorMessage = `${response.data.ResponseStatus.Message}\n`;
                const errors = response.data.Failed.map((failed: any) => `• ${format(failed.From, 'yyyy-MM-dd p')} - ${format(failed.To, 'yyyy-MM-dd p')} ${failed.Reason}`).join('\n')

                yield put(
                    actions.CREATE_BOOKING.failure(
                        throwSubmissionError({
                            _error: `${errorMessage}${errors}`,
                        })
                    )
                );
            }           
        }
        
    } catch (error) {
        yield put(actions.CREATE_BOOKING.failure(throwSubmissionError(error)));
    }
}
