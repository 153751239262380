import { createApi, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { definitions, operations } from '@/apitypes';
import {
    CreateInvoiceType,
    EaccountingArticle,
    EaccountingInvoice,
    Paging,
    PaymentTerm,
    Price,
    Service,
    ServicePriceMapping,
    CodelockSettings,
    CreateInvoiceResponse,
    DefaultPriceArticle,
    UpdateEaccountingSettingsParams,
    EaccountingSettingsResponse,
    Article,
	Articles,
	GetArticlesQuery,
	CreateArticleQuery,
    CurrencyQuery,
    Currency,
    RebateCode,
	GetRebateCodesQuery,
	UpdateRebateCodeQuery,
	RebateCodeResponse
} from '@/types';
import { getCurrentLanguageCode } from '@/utils/i18n';
import keycloak from '@/keycloak';
export type User = definitions['CurrentUserQueryResponse'];

const transformErrorResponse = (apiResponse: FetchBaseQueryError, meta: FetchBaseQueryMeta | undefined) => {
    const method = meta?.request.method;
    const url = meta?.request.url;
    const requestData = meta?.request.body;
    return {
        ...apiResponse,
        method,
        url,
        requestData,
        correlationId: meta?.response?.headers?.get('x-correlation-id'),
    };
}

export const apiService = createApi({
    reducerPath: 'bmApi',
    tagTypes: ['ServicePriceMappings', 'Invoices', 'Settings', 'Auth', 'article', 'articles', 'rebateCode', 'rebateCodes'],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
            'x-language': getCurrentLanguageCode(),
        },
        prepareHeaders(headers, api) {
            const bearerToken = keycloak?.token
            const sessionId = keycloak?.sessionId;

            if (bearerToken && sessionId) {
                headers.set('Authorization', `Bearer ${bearerToken}`);
                headers.set('X-Ss-Id', `${sessionId}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => {
        return {
            getEaccountingArticles: builder.query<EaccountingArticle[], void>({
                query: () => 'eaccounting/articles',
                providesTags: ['ServicePriceMappings'],
                transformErrorResponse
            }),
            getEaccountingPriceMappings: builder.query<
                ServicePriceMapping[],
                { CompanyId: string }
            >({
                query: ({ CompanyId }) => ({
                    url: '/services/prices/mappings',
                    method: 'get',
                    params: {
                        CompanyId,
                    },
                }),
                providesTags: ['ServicePriceMappings'],
                transformErrorResponse  
            }),
            getDefaultEaccountingPriceMappings: builder.query<
                DefaultPriceArticle[],
                { CompanyId: string }
            >({
                query: ({ CompanyId }) => ({
                    url: '/eaccounting/defaultarticles',
                    method: 'get',
                    params: {
                        CompanyId,
                    },
                }),
                providesTags: ['ServicePriceMappings'],
                transformErrorResponse
            }),
            updateEaccountingSettings: builder.mutation<
                DefaultPriceArticle[],
                UpdateEaccountingSettingsParams
            >({
                query: (data) => ({
                    url: '/eaccounting/settings',
                    method: 'put',
                    body: data,
                }),
                invalidatesTags: ['Settings'],
                transformErrorResponse
            }),
            getEaccountingSettings: builder.query<
                EaccountingSettingsResponse,
                { CompanyId?: string }
            >({
                query: ({ CompanyId }) => ({
                    url: '/eaccounting/settings',
                    method: 'get',
                    params: {
                        CompanyId,
                    },
                }),
                providesTags: ['Settings'],
                transformErrorResponse
            }),
            getEaccountingPrices: builder.query<Price[], operations['ServiceQuery_Get']['parameters']['query'] | void>({
                transformResponse: (res: Paging<Service>) =>
                    res.Results.map((s) =>
                        s.Prices.map((p) => ({ ...p, _serviceName: s.Name }))
                    ).flat(),
                query: (payload = {} as operations['ServiceQuery_Get']['parameters']['query']) => ({
                    url: '/services',
                    method: 'get',
                    params: {
                        IncludePrices: true,
                        ...payload,
                    },
                }),
                providesTags: ['ServicePriceMappings'],
                transformErrorResponse
            }),
            getEaccountingPaymentTerms: builder.query<PaymentTerm[], {} | void>({
                query: (payload = {}) => ({
                    url: '/eaccounting/termsofpayments',
                    method: 'get',
                }),
            }),
            getEaccountingNotes: builder.query<any[], {} | void>({
                query: (payload = {}) => ({
                    url: '/eaccounting/notes',
                    method: 'get',
                }),
                transformErrorResponse
            }),
            getEaccountingCustomers: builder.query<any[], {} | void>({
                query: (payload = {}) => ({
                    url: '/eaccounting/customers',
                    method: 'get',
                }),
                transformErrorResponse
            }),
            getEaccountingInvoices: builder.query<
                EaccountingInvoice[],
                { BookingId?: number } | void
            >({
                query: (payload = {}) => ({
                    url: '/eaccounting/invoices',
                    method: 'get',
                    params: {
                        ...payload,
                    },
                }),
                providesTags: ['Invoices'],
                transformErrorResponse
            }),
            getEaccountingInvoiceDrafts: builder.query<
                EaccountingInvoice[],
                { BookingId?: number } | void
            >({
                query: (payload = {}) => ({
                    url: '/eaccounting/invoicedrafts',
                    method: 'get',
                    params: {
                        ...payload,
                    },
                }),
                providesTags: ['Invoices'],
                transformErrorResponse
            }),
            createEaccountingInvoice: builder.mutation<CreateInvoiceResponse, CreateInvoiceType>({
                query: (data) => ({
                    url: '/eaccounting/invoice',
                    method: 'post',
                    body: data,
                }),
                invalidatesTags: ['Invoices'],
                transformErrorResponse
            }),
            createEaccountingInvoiceDraft: builder.mutation<
                CreateInvoiceResponse,
                CreateInvoiceType
            >({
                query: (data) => ({
                    url: '/eaccounting/invoicedraft',
                    method: 'post',
                    body: data,
                }),
                invalidatesTags: ['Invoices'],
                transformErrorResponse
            }),
            updateEaccountingPriceArticleMappings: builder.mutation<
                operations['EAccountingUpdatePricesMappingarticlemappings_Create']['responses']['200']['schema'],
                operations['EAccountingUpdatePricesMappingarticlemappings_Create']['parameters']['body']['body']
            >({
                query: (data) => ({
                    url: '/eaccounting/article/mappings',
                    method: 'put',
                    body: data,
                }),
                invalidatesTags: ['ServicePriceMappings'],
                transformErrorResponse
            }),
            deletePriceArticleMapping: builder.mutation<void, string>({
                query: (id) => ({
                    url: `/services/prices/mappings/${id}`,
                    method: 'delete',
                }),
                invalidatesTags: ['ServicePriceMappings'],
                transformErrorResponse
            }),
            checkEaccountingAuth: builder.query<any, {} | void>({
                query: () => ({
                    url: '/eaccounting/check',
                    method: 'get',
                }),
                providesTags: ['Auth'],
            }),
            eaccountingLogout: builder.mutation<void, void>({
                query: () => ({
                    url: '/eaccounting/logout',
                    method: 'post',
                }),
                invalidatesTags: ['Auth', 'Invoices', 'ServicePriceMappings', 'Settings'],
                transformErrorResponse
            }),
            getUsers: builder.query<any, {} | void>({
                // transformResponse: (res: Paging<Service>) => res.Results.map(s => s.Prices).flat(),
                query: () => ({
                    url: '/users',
                    method: 'get',
                }),
                transformErrorResponse
            }),
            getCodelockSettings: builder.query<CodelockSettings, {} | void>({
                query: () => ({
                    url: '/codelock/settings',
                    method: 'get',
                    params: {
                        IncludeCodeLockSystemOptions: true,
                    },
                }),
                transformErrorResponse
            }),
            // curl 'https://api-staging.bookmore.com/codelock/settings?IncludeCodeLockSystemOptions=true'
            resyncCodelock: builder.mutation<any, { bookingId: string; companyId: string }>({
                query: ({ bookingId, companyId }) => ({
                    url: `/codelock/resync/${bookingId}`,
                    method: 'post',
                    body: {
                        CompanyId: companyId,
                    },
                }),
                transformErrorResponse
            }),
            sendForApproval: builder.mutation<any, { companyId: string}>({
                query: ( { companyId }) => ({
                    url: `/companies/${companyId}/sendforapproval`,
                    method: 'put'
                }),
                transformErrorResponse
            }),
            getArticles: builder.query<Articles, GetArticlesQuery>({
                query: (params) => ({
                    url: "/articles",
                    method: "get",
                    params,
                }),
                providesTags: ['articles'],
                transformErrorResponse
            }),
             updateArticle: builder.mutation<
                Article,
                CreateArticleQuery & { Id: string}
            >({
                query: (data) => ({
                    url: `/articles/${data.Id}`,
                    method: "put",
                    body: data,
                }),
                invalidatesTags: ['articles'],
                transformErrorResponse
            }),
             createArticle: builder.mutation<
                Article,
                CreateArticleQuery
            >({
                query: (data) => ({
                    url: "/articles",
                    method: "post",
                    body: data,
                }),
                transformErrorResponse,
                invalidatesTags: ['articles'],
            }),
             deleteArticle: builder.mutation<
                void,
                { id: string }
            >({
                query: (params) => ({
                    url: `/articles/${params.id}`,
                    method: "delete"
                }),
                invalidatesTags: ['articles'],
                transformErrorResponse
            }),
            getCurrencies: builder.query<Currency[], CurrencyQuery>({
                query: (params) => ({
                    url: '/currencies',
                    method: 'get',
                    params: {
                        Active: true,
                        ...params
                    },
                }),
                transformErrorResponse
            }),
            getRebateCodes: builder.query<RebateCodeResponse, GetRebateCodesQuery>({
                query: (params) => ({
                    url: "/rebatecodes",
                    method: "get",
                    params,
                }),
                providesTags: ['rebateCodes'],
                transformErrorResponse
            }),
            updateRebateCode: builder.mutation<
              void,
              { id: string }
          >({
              query: (params) => ({
                  url: `/rebatecodes/${params.id}`,
                  method: "put"
              }),
              invalidatesTags: ['rebateCodes'],
              transformErrorResponse
          }),
              createRebateCode: builder.mutation<
                RebateCode,
                UpdateRebateCodeQuery
            >({
                query: (data) => ({
                    url: "/rebatecodes",
                    method: "post",
                    body: data,
                }),
                invalidatesTags: ['rebateCodes'],
                transformErrorResponse
            }),
              deleteRebateCode: builder.mutation<
                void,
                { id: string }
            >({
                query: (params) => ({
                    url: `/rebatecodes/${params.id}`,
                    method: "delete"
                }),
                invalidatesTags: ['rebateCodes'],
                transformErrorResponse
            }),
        };
    },
});

// TODO optimize: skip eaccounting endpoints when eaccounting is not enabled

export const {
    useGetEaccountingArticlesQuery,
    useGetDefaultEaccountingPriceMappingsQuery,
    useGetEaccountingPriceMappingsQuery,
    useGetEaccountingPricesQuery,
    useGetEaccountingPaymentTermsQuery,
    useCreateEaccountingInvoiceMutation,
    useCreateEaccountingInvoiceDraftMutation,
    useGetEaccountingNotesQuery,
    useGetEaccountingCustomersQuery,
    useGetEaccountingInvoicesQuery,
    useCheckEaccountingAuthQuery,
    useGetUsersQuery,
    useResyncCodelockMutation,
    useGetCodelockSettingsQuery,
    useUpdateEaccountingSettingsMutation,
    useGetEaccountingSettingsQuery,
    useEaccountingLogoutMutation,
    useUpdateEaccountingPriceArticleMappingsMutation,
    useSendForApprovalMutation,
    useUpdateArticleMutation,
    useGetArticlesQuery,
    useLazyGetArticlesQuery,
    useCreateArticleMutation,
    useDeleteArticleMutation,
    useGetCurrenciesQuery,
    useGetRebateCodesQuery,
    useLazyGetRebateCodesQuery,
    useUpdateRebateCodeMutation,
    useCreateRebateCodeMutation,
    useDeleteRebateCodeMutation
} = apiService;

export default apiService;
