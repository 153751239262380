import { SubmissionError } from 'redux-form';
import convertErrorToFormError from './convertErrorToFormError';

export default function throwSubmissionError(error: any, prefix?: string) {
    return new SubmissionError(convertErrorToFormError(error, prefix));
}

export const transformRtkError = (apiResponse: any) => {
    return { status: apiResponse.status, response: apiResponse};
}
