import { getFormValues } from 'redux-form';

import { createSelector } from 'reselect';
import { getServices } from './services';
import { Service } from '../../types';
import * as actions from '../actions';
import { Reducer, AnyAction } from 'redux';
import { cloneDeep } from 'lodash';

// For price request use form.booking.PriceDate and form.booking.PriceTime
const initialState = {
    finalService: {
        isLoading: false,
        isLoaded: true,
        hasError: false,
        error: undefined,
        data: null,
    },
    quantities: [],
    // Used to switch between month/week in calendar and for getting startDate/endDate values
    navigationDate: new Date(),
    submissionResult: {
        created: [],
        failed: [],
    },
    addedToQueue: {
        isLoading: false,
        isLoaded: false,
        hasError: false,
        error: undefined,
        data: null,
    },
    time: undefined
};

// ==========================
// SELECTORS
// ==========================
export const selectBookingFormValues = getFormValues('booking');

export const selectFinalService = (state: any) => state.booking.finalService;

export function getServiceQuantities(service: Service, priceId?: number) {
    let quantities: any[] = [];

    if (!service || !service.Prices) {
        return [];
    }

    const hasMultiplePrices = service.Prices && service.Prices.length > 1;
    const hasGroupBooking =
        service.GroupBooking.Active && service.GroupBooking.Min !== service.GroupBooking.Max;
    const hasMultipleResource =
        service.MultipleResource.Active &&
        service.MultipleResource.Min !== service.MultipleResource.Max;

    // Return setup price as quantities
    if (!(hasGroupBooking || hasMultipleResource)) {
        // Quantity if no prices
        if (service.Prices && service.Prices.length === 0) {
            quantities = [{ Quantity: 1 }];
        }
    } else

    // Return service count as quantities
    if (!hasMultiplePrices && hasGroupBooking) {
        quantities = [{ Quantity: service.GroupBooking.Min || 1 }];
    } else

    // Return resource count as quantities
    if (!hasMultiplePrices && hasMultipleResource) {
        quantities = [{ Quantity: service.MultipleResource.Min || 1 }];
    }

    // Setup price automatically if only one available (no need to select)
    if (service.Prices && service.Prices.length === 1) {
        const { Id, Price, PriceText } = service.Prices[0];
        quantities = [{ Quantity: 1, PriceId: Id, Price, PriceText }];
    } else

    if(hasMultiplePrices && priceId) {
        const { Id, Price, PriceText } = service.Prices.find((price) => price.Id === priceId)!;
        quantities = [{ Quantity: 1, PriceId: Id, Price, PriceText }];
    }

    return quantities;
}

export const selectSelectedService = createSelector(
    getServices,
    selectBookingFormValues,
    (services, formValues: any) => {
        if (!formValues) {
            return null;
        }
        return services.data.find((service: Service) => service.Id === formValues.ServiceId);
    }
);

export const selectResourcesToBook = createSelector(
    selectSelectedService,
    selectBookingFormValues,
    (service: Service, formValues: any) => {
        if (!formValues || !formValues.Resources) {
            return null;
        }
        const selectedResources: any[] = [];
        Object.keys(formValues.Resources).forEach((resourceTypeStringId) => {
            // '_1234' => 1234
            const id = parseInt(resourceTypeStringId.slice(1));
            const resourceType = service?.ResourceTypes.find(
                (resourceType) => resourceType.Id === id
            );
            if (resourceType) {
                const resource = resourceType.Resources.find(
                    (resource) => resource.Id === formValues.Resources[resourceTypeStringId]
                );
                if (resource) {
                    selectedResources.push({ resourceType, resource });
                }
            }
        });
        return selectedResources;
    }
);

// ==========================
// REDUCER
// ==========================
const reducer: Reducer<any, AnyAction> = (state = initialState, action) => {
    switch (action.type) {
        case actions.RESET_BOOKING: {
            return initialState;
        }
        case actions.FETCH_SERVICE_WITH_PRICE.REQUEST: {
            return {
                ...state,
                finalService: {
                    isLoading: true,
                    isLoaded: false,
                    hasError: false,
                    error: null,
                    data: state.finalService.data,
                },
                quantities: [],
            };
        }
        case actions.FETCH_SERVICE_WITH_PRICE.SUCCESS: {
            return {
                ...state,
                finalService: cloneDeep({
                    isLoading: false,
                    isLoaded: true,
                    hasError: true,
                    error: null,
                    data: action.payload,
                }),
                step: 1,
                quantities: getServiceQuantities(action.payload),
            };
        }
        case actions.UPDATE_SERVICE_QUANTITY: {
            return {
                ...state,
                quantities: getServiceQuantities(action.payload.service, action.payload.priceId)
            }
        }
        case actions.FETCH_SERVICE_WITH_PRICE.FAILURE: {
            return {
                ...state,
                finalService: {
                    isLoading: false,
                    isLoaded: false,
                    hasError: true,
                    error: action.payload,
                    data: initialState.finalService.data,
                },
                quantities: [],
            };
        }
        case actions.CHANGE_BOOKING_NAVIGATION_DATE: {
            return { ...state, navigationDate: action.payload };
        }
        case 'CHANGE_QUANTITY': {
            const { quantities } = state;
            const nextQuantities = [];
            // Don't allow negative quantity values
            const nextQuantity = Math.max(0, action.value);

            if (action.price) {
                let replaced = false;

                quantities.forEach((quantity: any) => {
                    if (quantity.PriceId === action.price.Id) {
                        nextQuantities.push({
                            ...quantity,
                            Quantity: nextQuantity,
                        });

                        replaced = true;
                    } else {
                        nextQuantities.push(quantity);
                    }
                });

                if (!replaced) {
                    nextQuantities.push({
                        ...action.price,
                        PriceId: action.price.Id,
                        Quantity: 1,
                    });
                }
            } else {
                nextQuantities.push({
                    Quantity: nextQuantity,
                });
            }

            return {
                ...state,
                quantities: nextQuantities,
            };
        }
        case 'SET_PRICE': {
            return {
                ...state,
                quantities: [
                    {
                        PriceId: action.price.Id,
                        Price: action.price.Price,
                        Quantity: 1,
                    },
                ],
            };
        }
        case 'SET_RESOURCE_COUNT': {
            return {
                ...state,
                quantities: [
                    {
                        Quantity: action.resourceCount,
                    },
                ],
            };
        }
        case 'SET_SERVICE_COUNT': {
            return {
                ...state,
                quantities: [
                    {
                        Quantity: action.serviceCount,
                    },
                ],
            };
        }
        case actions.CREATE_BOOKING.REQUEST:
        case actions.CREATE_BOOKING.FAILURE: {
            return {
                ...state,
                submissionResult: initialState.submissionResult,
            };
        }
        case actions.UPDATE_BOOKING_TIME: {
            return {
                ...state,
                time: action.payload
            }
        }
        case actions.CREATE_BOOKING.SUCCESS: {
            return {
                ...state,
                addedToQueue: {
                    isLoading: false,
                    isLoaded: true,
                    hasError: false,
                    error: undefined,
                    data: action.payload.addedToQueue
                },
                submissionResult: {
                    created: action.payload.Created,
                    failed: action.payload.Failed,
                },
            };
        }
        default:
            return state;
    }
};

export default reducer;
